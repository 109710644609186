import LockedWarning from '@/shared/locked_warning.vue'
import LockerWidget from '@/shared/locker_widget.vue'
import pubsub_mixin from '@/shared/pubsub_mixin'
export default {
  components: { LockedWarning, LockerWidget },
  data() {
    return {
      locked: false,
      readonly: false,
      lockedBy: null
    }
  },
  mixins: [pubsub_mixin],
  computed: {
    lockingParams() {
      return { locked: this.locked, lockedBy: this.lockedBy, readonly: this.readonly }
    },
    lockedCss() {
      if (this.locked && this.readonly) {
        return 'locked'
      }
    }
  },
  methods: {
    onLockedUpdate(details) {
      this.updateLocking(details)
    },
    onEditModeChange(newMode) {
      if (this.$refs.locker == null) {
        console.error('LOCKING requires "locker" ref to be set to the LockerWidget')
        return
      }
      // console.log("LOCKING onEditModeChange", newMode)
      if (newMode === true) {
        this.$refs.locker.lock()
      } else {
        this.$refs.locker.unlock()
      }
    },
    updateLocking({ locked, lockedBy, readonly }) {
      this.locked = locked
      this.lockedBy = lockedBy
      this.readonly = readonly
      // console.log("LOCKING updateLocking locked: ", this.locked, 'readonly: ', this.readonly, 'lockedBy:', this.lockedBy)
    },
    onReservationLockChange(reservationId, context, callback) {
      this.fayeSubscribe('/locking/Reservation/' + reservationId, context, (channel, message) => {
        // console.log("LOCKING onReservationLockChange", message)
        if (this.lockedByActiveUser(message)) {
          return
        }
        this.$emit('update:loading', true)
        callback()
      })
    },
    onReservationLockRemove(reservationId, context) {
      this.fayeUnsubscribe('/locking/Reservation/' + reservationId, context)
    },
    onClientLockChange(clientId, context, callback) {
      this.fayeSubscribe('/locking/Client/' + clientId, context, (channel, message) => {
        // console.log("LOCKING onClientLockChange", message)
        if (this.lockedByActiveUser(message)) {
          return
        }
        callback()
      })
    },
    onLocationLockChange(locationId, context, callback) {
      this.fayeSubscribe('/locking/Location/' + locationId, context, (channel, message) => {
        // console.log("LOCKING onLocationLockChange", context, message)
        if (this.lockedByActiveUser(message)) {
          return
        }
        callback()
      })
    },
    lockedByActiveUser(message) {
      const s = (this.activeUser.id == message.locked_by_id) && (this.activeUser.class == message.locked_by)
      if (s === true) {
        // console.log("LOCKING locked by YOU")
      } else {
        // console.log("LOCKING NOT locked by YOU, but", message, 'you are:', this.activeUser)
      }
      return s
    }
  }
}
