<template>
  <div>
    <template v-if="forcedAllowed && locked && readonly">
      <a v-if="locked" @click="forceLock" href="#" class="h4"><i class="h4 text-success fas fa-lock-open"></i>Force Lock</a>
    </template>
    <template v-if="false && !readonly">
      <a v-if="locked" @click="unlock" href="#" class="h4"><i class="h4 text-danger fas fa-lock"></i></a>
      <a v-if="!locked" @click="lock" href="#" class="h4"><i class="h4 text-success fas fa-lock-open"></i></a>
    </template>
    <template v-if="locked && !readonly">
      <i class="h4 text-danger fas fa-lock" v-b-tooltip.hover title="Locked by you"></i>
    </template>
    <locked-warning v-if="lockedBy" :locked="readonly" :locked-by="lockedBy" />
  </div>
</template>
<script>
import api from '@/clients/api'
import LockedWarning from '@/shared/locked_warning.vue'
import Flasher from '@/flasher'
import Vue from 'vue'
Vue.use(Flasher)

export default {
  components: {
    LockedWarning
  },
  props: {
    recordClass: {
      type: String,
      required: true
    },
    recordId: {
      type: Number,
      required: true
    },
    locked: {
      type: Boolean,
      required: true
    },
    lockedBy: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    forcedAllowed() {
      return this.isStaff
    }
  },
  methods: {
    forceLock() {
      // console.log("LOCKER WIDGET forceLock trying to force lock record")
      return new Promise((resolve, reject) => {
        api.forceLockRecord(this.recordClass, this.recordId).then(response => {
          // console.log('LOCKER WIDGET forceLock record locked', response.data)
          this.emitUpdateLocked(response.data)
          resolve()
        })
      })
    },
    lock() {
      // console.log("LOCKER WIDGET lock trying to lock record")
      return new Promise((resolve, reject) => {
        api.lockRecord(this.recordClass, this.recordId).then(response => {
          // console.log('LOCKER WIDGET lock record locked', response.data)
          this.emitUpdateLocked(response.data)
          resolve()
        })
      })
    },
    unlock() {
      // console.log("LOCKER WIDGET unlock trying to unlock record, lockedBy", this.lockedBy)
      if (this.lockedBy) {
        if ( (this.activeUser.id == this.lockedBy.id) && (this.activeUser.class == this.lockedBy.class) ) {
          // console.log("LOCKER WIDGET unlock LOCKED BY YOU")
          //return
        }
      }
      return new Promise((resolve, reject) => {
        api.unlockRecord(this.recordClass, this.recordId).then(response => {
          // console.log('LOCKER WIDGET unlock', response.data)
          this.emitUpdateLocked(response.data)
          resolve()
        })
      })
    },
    emitUpdateLocked(data) {
      this.$emit('update:locked', { locked: data.locked, lockedBy: data.locked_by, readonly: data.readonly })
    }
  }
}
</script>
