<template>
  <div v-if="locked" class="row mx-0 my-4 red">
    <div class="col-sm-12" v-html="warning"></div>
  </div>
</template>
<script>
export default {
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    lockedBy: {
      type: Object,
      required: true
    },
    model: {
      type: String,
//      required: true
    }
  },
  computed: {
    warning() {
      //if (this.model == 'client') {
        return '<i class="fas fa-lock"></i> This area is locked for editing because ' + this.lockedBy.str + ' is updating it. It will be unlocked and reloaded automatically in few minutes after those updates have been finished.'
      //}
    }
  }
}
</script>
